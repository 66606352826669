html[data-theme="dark"] {
	--Navy: #0a192f;
	--Light-Navy: #172a45;
	--Slate: #8892b0;
	--Light-Slate: #a8b2d1;
	--Lightest-Slate: #ccd6f6;
	--Green: #64ffda;
	--Green-filter: rgba(100, 255, 218, 0.3);
}

html[data-theme="light"] {
	--Navy: #edf5ff;
	--Light-Navy: #ffeded;
	--Slate: #594b3b;
	--Lightest-Slate: black;
	--Light-Slate: black;
	--Green: #f6b39d;
	--Green-filter: rgba(246, 179, 157, 0.3);
}

html, body {
	margin: 0;
	overflow: hidden;
	height: 100%;
}

body {
	margin: 0;
	overflow: auto;
	overflow-x: hidden;
    height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .preloader-wrapper{
	width: 100%;
	height: 100%;
	background-color: var(--Navy);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.preloader {
	position: relative;
	width: 8rem;
	height: 8rem;
	border-radius: 50%;
	animation-name: spin;
	animation-duration: 1.5s;
}

.preloader::before,
.preloader:after{
	content: "";
	position: absolute;
	border-radius: 50%;
}

.preloader:before {
	width: 100%;
	height: 100%;
	background-image:linear-gradient(90deg, #a8b2d1 0%,#64ffda 100%);
	animation: spin .5s infinite linear;
}
.preloader:after {
	width: 90%;
	height: 90%;
	background-color: var(--Navy);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
} */
